import React, { PureComponent, useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  Area,
  ResponsiveContainer,
  LineChart,
  Line
} from "recharts";
import States from "./States";
import { Select, Form, Button, Switch } from "antd";
import styled from "styled-components";
import { set } from "lodash";
import Colors from "./Colors";

const PageContainer = styled.div`
  width: 100vw;
  height: 100%;
  max-height: 100vh;
  display: grid;
  grid-template-columns: 315px [middle-separator] 1fr [end];
  grid-template-rows: 1fr [middle-separator] 450px [end];

  @media (max-width: 812px) {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
`;

const LeftSidebar = styled.div`
  grid-column-start: 1;
  grid-column-end: span middle-separator;
  grid-row-start: 1;
  grid-row-end: span end;

  display: flex;
  flex-direction: column;
  padding: 25px;

  @media (max-width: 812px) {
    padding: 0px 25px;
  }
`;

const ResultsView = styled.div`
  grid-column-start: middle-separator;
  grid-column-end: span end;
  grid-row-start: 1;
  grid-row-end: span end;
  
  @media (max-width: 812px) {
    padding: 20px 20px 60px;
  }
`;

const MainForm = styled(Form)`
  margin-top: 20px;
`;

export default () => {
  const [data, setData] = useState([]);
  const [selectedStates, setSelectedStates] = useState(["FL", "CA"]);
  const [subStates, setSubStates] = useState([]);
  const [normalized, setNormalized] = useState(true);
  const [axisLabel, setAxisLabel] = useState("# of cases");
  const [yAxisScale, setYAxisScale] = useState("linear")

  useEffect(() => {
    handleSubmit(["CA", "NY"]);
  }, []);

  function handleSelectStateChange(value) {
    setSelectedStates(value);
  }

  function handleSubmit() {
    const statesString = selectedStates.join();
    setSubStates(
      selectedStates.reduce(function(o, k) {
        o[k] = States[k];
        return o;
      }, {})
    );
    var label = normalized ? "# of cases / million residents" : "# of cases";
    setAxisLabel(label);

    fetch(
      `https://api-dev.intersectlabs.io/covid/?county_level=false&states=${statesString}&start=2020-02-28&normalized=${normalized.toString()}`,
      {
        method: "GET"
      }
    )
      .then(res => res.json())
      .then(({ values }) => {
        const byDate = {};
        values.map(val => {
          set(byDate, [val[0], val[1]], Math.ceil(val[2]));
        });
        const data = Object.keys(byDate).map(date => ({
          ...byDate[date],
          name: date
        }));
        setData(data);
      })
      .catch(error => {
        console.error(`Error fetching data list`, error);
        // notification.error({
        //   message: "Couldn't fetch data",
        //   description:
        //     "Please check your internet connection or contact support."
        // });
      });
  }

  function onToggleChange(checked) {
    setNormalized(checked);
  }

  function onLogToggleChange(checked) {
    if (checked === true) {
      setYAxisScale("log")
    } else {
      setYAxisScale("linear")
    }
  }

  return (
    <PageContainer>
      <LeftSidebar>
        <MainForm layout="vertical">
          <Form.Item label="States" name="states">
            <Select
              mode="multiple"
              onChange={handleSelectStateChange}
              defaultValue={selectedStates}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.keys(States).map(state => (
                <Select.Option key={state}>{States[state]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Normalize? (number of cases/1 million residents)"
            name="normalized"
          >
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked
              onChange={onToggleChange}
            />
          </Form.Item>
          <Form.Item lable="Submit" name="submit">
            <Button type="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Form.Item>
        </MainForm>
      </LeftSidebar>
      <ResultsView>
        <ResponsiveContainer width="100%" height={500}>
          <LineChart
            width={500}
            height={400}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              domain={[0, 'dataMax']}
              label={{ value: axisLabel, angle: -90, position: "insideLeft" }}
              scale={yAxisScale} domain={[1, 'dataMax']} allowDataOverflow={true}
            />
            <Tooltip />
            {Object.values(subStates).map((state, index) => {
              return (
                <Line
                  type="monotone"
                  dataKey={state}
                  stackId="a"
                  stroke={Colors[index % Colors.length]}
                  fill={Colors[index % Colors.length]}
                  strokeWidth={3}
                />
              );
            })}
            <Legend />
          </LineChart>
        </ResponsiveContainer>
        <Switch 
          checkedChildren="Log"
          unCheckedChildren="Linear"
          onChange={onLogToggleChange}/>
      </ResultsView>
    </PageContainer>
  );
};
