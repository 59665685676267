import React, { useState, useEffect } from "react";
import States from "./States";
import { Select, Form, Table, Tooltip, Switch } from "antd";
import styled from "styled-components";
import { set } from "lodash";
import VirtualDataTable from "./VirtualDataTable";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 315px [middle-separator] 1fr [end];
  grid-template-rows: 1fr [end];

  @media (max-width: 812px) {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
`;

const LeftSidebar = styled.div`
  grid-column-start: 1;
  grid-column-end: span middle-separator;
  grid-row-start: 1;
  grid-row-end: span end;

  display: flex;
  flex-direction: column;
  padding: 25px;

  @media (max-width: 812px) {
    padding: 0px 25px;
  }
`;

const ResultsView = styled.div`
  grid-column-start: middle-separator;
  grid-column-end: span end;
  grid-row-start: 1;
  grid-row-end: span end;
  overflow: auto;
`;

const MainForm = styled(Form)`
  margin-top: 20px;
`;

const RowCell = styled.div`
  min-width: 10px;
  min-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getRedDot = diameter => {
  return (
    <div
      style={{
        height: diameter,
        width: diameter,
        backgroundColor: "#FF8080",
        borderRadius: "50%"
      }}
    ></div>
  );
};

export default () => {
  const [selectedState, setSelectedState] = useState("CA");
  const [data, setData] = useState();
  const [columns, setColumns] = useState([]);
  const [normalized, setNormalized] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedState, normalized]);

  const fetchData = () => {
    setLoading(true);
    fetch(
      `https://api-dev.intersectlabs.io/covid/?county_level=true&states=${selectedState}&start=2020-02-28&normalized=${normalized.toString()}`,
      {
        method: "GET"
      }
    )
      .then(res => res.json())
      .then(({ values }) => {
        const byCounty = {};
        const allDates = [];
        let currentMax = 1;
        values.map(val => {
          const county = val[1];
          const count = val[3];
          set(byCounty, [county, val[0]], count);
          set(byCounty, [county, "County"], county);
          if (currentMax < count) currentMax = count;

          if (!allDates.includes(val[0])) {
            allDates.push(val[0]);
          }
        });
        setData(Object.values(byCounty));

        const columns = allDates.map(date => {
          return {
            title: date,
            dataIndex: date,
            key: date,
            width: 40,
            render: (text, record) => {
              const diameter = Math.max(Math.ceil(text / 50), 4);
              // if (isNaN(diameter)) return;
              return (
                <Tooltip placement="topRight" title={`${date}: ${text}`}>
                <RowCell>
                  <div
                    style={{
                      height: diameter,
                      width: diameter,
                      backgroundColor: "#FF8080",
                      borderRadius: "50%"
                    }}
                  ></div>
                </RowCell>
                </Tooltip>
              );
            }
          };
        });

        columns.unshift({
          title: "County",
          dataIndex: "County",
          key: "County",
          fixed: "left"
        });

        setColumns(columns);
        setLoading(false);
      })
      .catch(error => {
        console.error(`Error fetching data list`, error);
        setLoading(false);
      });
  };

  return (
    <PageContainer>
      <LeftSidebar>
        <MainForm layout="vertical">
          <Form.Item label="States" name="states">
            <Select
              showSearch
              defaultValue="CA"
              onChange={setSelectedState}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.keys(States).map(state => (
                <Select.Option key={state}>{States[state]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Normalize? (number of cases/1 million residents)"
            name="normalized"
          >
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={checked => setNormalized(checked)}
            />
          </Form.Item>
        </MainForm>
      </LeftSidebar>
      <ResultsView>
        <VirtualDataTable
          loading={loading}
          className="project-data-table"
          columns={columns}
          rowKey={(record, index) => index}
          // rowGetter={i => selectedData.values[i]}
          dataSource={data}
          rowsCount={0}
          pagination={false}
          showHeader={false}
          scroll={{ x: "max-content" }}
          // minHeight={onlyData ? "90vh" : 400}
          // enableCellSelect={false}
        />
      </ResultsView>
    </PageContainer>
  );
};
