export default [
  "#FF6A6A",
  "#000000",
  "#CE42FF",
  "#4CE485",
  "#EABD20",
  "#00A545",
  "#CBDF4F",
  "#828282",
  "#FFA85A",
  "#6184FF",
  "#B6CBC7",
  "#CF9500",
  "#DCD989"
];
