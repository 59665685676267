import React, { useState, useEffect, useRef, useMemo } from "react";
import { VariableSizeGrid as Grid } from "react-window";
import ResizeObserver from "rc-resize-observer";
import classNames from "classnames";
import { Table } from "antd";
import { Resizable } from "react-resizable";

const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th
        {...restProps}
        style={{ backgroundColor: "#fafafa", width, cursor: "default" }}
      />
    </Resizable>
  );
};

export default props => {
  const { columns, scroll, className } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const widthColumnCount = columns.filter(({ width }) => !width).length;
  const [columnWidths, setColumnWidths] = useState(
    Array(columns.length).fill(100)
  );

  const mergedColumns = columns.map((column, index) => {
    if (column.width) {
      return column;
    }

    return {
      ...column,
      key: column.name,
      title: column.name,
      // dataIndex: column.key, ellipsis: true, width: Math.max(200, Math.floor(tableWidth / widthColumnCount)) };
      dataIndex: column.key,
      ellipsis: true,
      width: columnWidths.length > index ? columnWidths[index] : 100
    };
  });
  const gridRef = useRef();
  const [connectObject] = useState(() => {
    const obj = {};
    Object.defineProperty(obj, "scrollLeft", {
      get: () => null,
      set: scrollLeft => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft
          });
        }
      }
    });
    return obj;
  });

  const resetVirtualGrid = () => {
    if (gridRef.current) {
      gridRef.current.resetAfterIndices({
        columnIndex: 0,
        shouldForceUpdate: false
      });
    }
  };

  useEffect(() => resetVirtualGrid, []);
  useEffect(() => resetVirtualGrid, [tableWidth]);
  useEffect(() => {
    const diff = columnWidths.length - columns.length;
    if (diff < 0) {
      setColumnWidths([...columnWidths, ...Array(Math.abs(diff)).fill(100)]);
    } else if (diff > 0) {
      const colWidthsCopy = [...columnWidths];
      colWidthsCopy.length = columns.length;
      setColumnWidths(colWidthsCopy);
    }
    resetVirtualGrid();
  }, [columns]);

  const itemData = useMemo(
    () => ({
      hoveredRowIndex,
      setHoveredRowIndex
    }),
    [hoveredRowIndex]
  );

  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    ref.current = connectObject;
    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={mergedColumns.length}
        columnWidth={index => {
          const width = columnWidths[index];
          return index === columnWidths.length - 1
            ? width - scrollbarSize - 1
            : width;
        }}
        height={scroll.y}
        rowCount={rawData.length}
        rowHeight={() => 45}
        width={tableWidth}
        onScroll={({ scrollLeft }) => {
          onScroll({
            scrollLeft
          });
        }}
      >
        {({ columnIndex, rowIndex, style }) => {
          let className = "virtual-table-cell";
          if (hoveredRowIndex === rowIndex) className += " hovered-cell";

          return (
            <div
              className={classNames(className, {
                "virtual-table-cell-last":
                  columnIndex === mergedColumns.length - 1
              })}
              onMouseEnter={() => setHoveredRowIndex(rowIndex)}
              style={style}
            >
              {rawData[rowIndex][mergedColumns[columnIndex].dataIndex]}
            </div>
          );
        }}
      </Grid>
    );
  };

  const handleResize = index => (e, data) => {
    setColumnWidths(columnWidths => {
      const nextColumns = [...columnWidths];
      nextColumns[index] = data.size.width;
      return nextColumns;
    });
    gridRef.current.resetAfterColumnIndex(index, false);
  };

  const updatedMergedColumns = mergedColumns.map((col, index) => ({
    ...col,
    onHeaderCell: column => {
      return {
        width: columnWidths[index],
        onResize: handleResize(index)
      };
    }
  }));

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width - 2);
      }}
    >
      <Table
        {...props}
        className={classNames(className, "virtual-table")}
        columns={updatedMergedColumns}
        pagination={false}
        bordered
        size="small"
        components={{
          body: renderVirtualList,
          header: {
            cell: ResizeableTitle
          }
        }}
      />
    </ResizeObserver>
  );
};
