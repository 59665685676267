import React, { PureComponent, useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  Area,
  ResponsiveContainer,
  Line,
  LineChart
} from "recharts";
import States from "./States";
import { Select, Form, Switch } from "antd";
import styled from "styled-components";
import { set } from "lodash";
import Colors from "./Colors";
import VirtualDataTable from "./VirtualDataTable";

const PageContainer = styled.div`
  width: 100vw;
  height: 100%;
  padding: 40px;
  max-height: 100vh;
  display: grid;
  grid-template-columns: 1fr [middle-separator] 360px [end];
  grid-template-rows: 1fr [middle-separator] 450px [end];

  @media (max-width: 812px) {
    padding: 20px 20px 60px;
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
`;

const ResultsView = styled.div`
  grid-column-start: 1;
  grid-column-end: span middle-separator;
  grid-row-start: 1;
  grid-row-end: span end;
`;

const RightSidebar = styled.div`
  grid-column-start: middle-separator;
  grid-column-end: span end;
  grid-row-start: 1;
  grid-row-end: span end;
  max-height: 500px;
  overflow: auto;
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;

  display: flex;
  flex-direction: column;
  margin-left: 25px;

  @media (max-width: 812px) {
    margin: 25px 25px 0px;
  }
`;

const MainForm = styled(Form)`
  margin-top: 20px;
`;

export default () => {
  const [nationwideData, setNationwideData] = useState([]);
  const [loading, setLoading] = useState(false);
  const columns = [{
    title: "State",
    dataIndex: "state",
    key: "state",
    render: (text) => <b>{text}</b>,
    width: 100,
  }, {
    title: "Cases",
    dataIndex: "cases",
    key: "cases",
    width: 40,
  }, {
    title: "Deaths",
    dataIndex: "deaths",
    key: "deaths",
    width: 40,
  }];
  const [statewiseData, setStatewiseData] = useState([]); 
  const [yAxisScale, setYAxisScale] = useState("linear")

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://api-dev.intersectlabs.io/covid/?county_level=false&states=All&start=2020-02-28&aggregated=True`,
      {
        method: "GET"
      }
    )
      .then(res => res.json())
      .then(({nationwide, statewise}) => {
        console.log(nationwide);
        const nationData = nationwide.values.map(([date, count]) => {
          return { name: date, USA: count };
        });
        const statesSorted = statewise.values.sort((a, b) => b[2] - a[2]);
        const stateData = statesSorted.map(([date, state, cases, deaths]) => {
          return { name: date, state, cases, deaths };
        })
        setStatewiseData(stateData);
        setNationwideData(nationData);
        setLoading(false);
      })
      .catch(error => {
        console.error(`Error fetching data list`, error);
        setLoading(false);
      });
  }, []);

  function onLogToggleChange(checked) {
    if (checked === true) {
      setYAxisScale("log")
    } else {
      setYAxisScale("linear")
    }
  }

  return (
    <PageContainer>
      <ResultsView>
        <ResponsiveContainer width="100%" height={500}>
          <LineChart
            width={500}
            height={400}
            data={nationwideData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              domain={[0, "dataMax"]}
              label={{
                value: "# of cases",
                angle: -90,
                position: "insideLeft"
              }}
              scale={yAxisScale} domain={[1, 'dataMax']} allowDataOverflow={true}
            />
            <Tooltip />

            <Line
              type="monotone"
              dataKey="USA"
              stroke={Colors[0]}
              strokeWidth={3}
            />
          </LineChart>
        </ResponsiveContainer>
        <Switch 
          checkedChildren="Log"
          unCheckedChildren="Linear"
          onChange={onLogToggleChange}/>
      </ResultsView>
      <RightSidebar>
      <VirtualDataTable
          loading={loading}
          className="project-data-table"
          columns={columns}
          rowKey={(record, index) => index}
          // rowGetter={i => selectedData.values[i]}
          dataSource={statewiseData}
          rowsCount={0}
          pagination={false}
          // showHeader={false}
          // minHeight={onlyData ? "90vh" : 400}
          // enableCellSelect={false}
        />
      </RightSidebar>
    </PageContainer>
  );
};
