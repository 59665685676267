import React, { useState, useEffect } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line
} from "recharts";
import States from "./States";
import { Select, Form, Button, Checkbox, Switch } from "antd";
import styled from "styled-components";
import { set } from "lodash";
import Colors from "./Colors";

const PageContainer = styled.div`
  width: 100vw;
  height: 100%;
  max-height: 100vh;
  display: grid;
  grid-template-columns: 315px [middle-separator] 1fr [end];
  grid-template-rows: 1fr [middle-separator] 450px [end];

  @media (max-width: 812px) {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
`;

const LeftSidebar = styled.div`
  grid-column-start: 1;
  grid-column-end: span middle-separator;
  grid-row-start: 1;
  grid-row-end: span end;

  display: flex;
  flex-direction: column;
  padding: 25px;

  @media (max-width: 812px) {
    padding: 0px 25px;
  }
`;

const ResultsView = styled.div`
  grid-column-start: middle-separator;
  grid-column-end: span end;
  grid-row-start: 1;
  grid-row-end: span end;

  @media (max-width: 812px) {
    padding: 20px 20px 60px;
  }
`;

const MainForm = styled(Form)`
  margin-top: 20px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;

  span {
    text-decoration: underline;
  }
`;

export default ({}) => {
  const [data, setData] = useState([]);
  const [selectedStates, setSelectedStates] = useState("CA");
  const [counties, setCounties] = useState([]);
  const [selectedCounties, setSelectedCounties] = useState([]);
  const [dataSubset, setDataSubset] = useState([]);
  const [normalized, setNormalized] = useState(true);
  const [axisLabel, setAxisLabel] = useState("# of cases/million residents");
  const [yAxisScale, setYAxisScale] = useState("linear")

  useEffect(() => {
    fetchData(true);
  }, []);

  useEffect(() => {
    fetchData(true);
  }, [selectedStates]);

  useEffect(() => {
    fetchData(false);
  }, [normalized]);

  useEffect(() => {
    setDataSubset(data.map(x => pick(x, [...selectedCounties, "name"])));
  }, [selectedCounties]);

  function onCountyCheckBoxChange(checkedCountyValues) {
    setSelectedCounties(checkedCountyValues);
  }

  const handleSelectStateChange = value => {
    setSelectedStates(value);
    setSelectedCounties([]);
  };

  function pick(obj, props) {
    "use strict";

    // Make sure object and properties are provided
    if (!obj || !props) return;

    // Create new object
    var picked = {};

    // Loop through props and push to new object
    props.forEach(function(prop) {
      picked[prop] = obj[prop];
    });

    // Return new object
    return picked;
  }

  const onNormalizedCheckChange = checked => {
    setNormalized(checked);
  };

  const fetchData = (updateCounties = false) => {
    fetch(
      `https://api-dev.intersectlabs.io/covid/?county_level=true&states=${selectedStates}&start=2020-02-28&normalized=${normalized.toString()}`,
      {
        method: "GET"
      }
    )
      .then(res => res.json())
      .then(({ values }) => {
        const byDate = {};
        values.map(val => {
          set(byDate, [val[0], val[1]], Math.ceil(val[3]));
        });
        const data = Object.keys(byDate).map(date => ({
          ...byDate[date],
          name: date
        }));

        const allCounties = Object.keys(data[data.length - 1]).filter(function(
          e
        ) {
          return e !== "name";
        });

        if (updateCounties) {
          setCounties(allCounties);
          setSelectedCounties(allCounties.slice(0, 5));
        }
        setData(data);

        var label = normalized
          ? "# of cases / million residents"
          : "# of cases";
        setAxisLabel(label);

        setDataSubset(data.map(x => pick(x, [...allCounties, "name"])));
      })
      .catch(error => {
        console.error(`Error fetching data list`, error);
      });
  };

  function onLogToggleChange(checked) {
    if (checked === true) {
      setYAxisScale("log")
    } else {
      setYAxisScale("linear")
    }
  }


  return (
    <PageContainer>
      <LeftSidebar>
        <MainForm layout="vertical">
          <Form.Item label="State" name="state">
            <Select
              showSearch
              defaultValue="CA"
              onChange={handleSelectStateChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.keys(States).map(state => (
                <Select.Option key={state}>{States[state]}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Normalize? (number of cases/1 million residents)"
            name="normalized"
          >
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked
              onChange={onNormalizedCheckChange}
            />
          </Form.Item>
          <ButtonRow>
            <Button type="link" onClick={() => setSelectedCounties(counties)}>
              Select All
            </Button>
            <Button type="link" onClick={() => setSelectedCounties([])}>
              Unselect All
            </Button>
          </ButtonRow>
          <Form.Item>
            <Checkbox.Group
              options={counties}
              value={selectedCounties}
              onChange={onCountyCheckBoxChange}
            />
          </Form.Item>
        </MainForm>
      </LeftSidebar>
      <ResultsView>
        <ResponsiveContainer width="100%" height={500}>
          <LineChart
            width={500}
            height={400}
            data={dataSubset}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              domain={[0, "dataMax"]}
              label={{ value: axisLabel, angle: -90, position: "insideLeft" }}
              scale={yAxisScale} domain={[1, 'dataMax']} allowDataOverflow={true}
            />
            <Tooltip />
            {Object.values(selectedCounties).map((state, index) => {
              return (
                <Line
                  key={state}
                  type="monotone"
                  dataKey={state}
                  stackId="a"
                  stroke={Colors[index % Colors.length]}
                  fill={Colors[index % Colors.length]}
                  strokeWidth={3}
                />
              );
            })}
            <Legend />
          </LineChart>
        </ResponsiveContainer>
        <Switch 
          checkedChildren="Log"
          unCheckedChildren="Linear"
          onChange={onLogToggleChange}/>
      </ResultsView>
    </PageContainer>
  );
};
