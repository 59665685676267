import React, { useState } from "react";
import "./App.css";
import styled from "styled-components";
import SpecificStatesVisualizer from "./SpecificStatesVisualizer";
import DailyChangeVisualizer from "./DailyChangeVisualizer";
import NationwideVisualizer from "./NationwideVisualizer";
import CountyLevelVisualizer from "./CountyLevelVisualizer";
import CountyHeatMap from "./CountyHeatMap";
import StateHeatMap from "./StateHeatMap";
import IntersectLogo from "./logo.svg";
import { Select } from "antd";

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  @media (max-width: 812px) {
    height: auto;
    max-height: 100%;
  }
`;

const VisualizationWrapper = styled.div`
  justify-content: stretch;
  align-items: stretch;
  height: 85vh;
  overflow: auto;

  @media (max-width: 812px) {
    height: auto;
  }
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  height: 10vh;

  @media (max-width: 812px) {
    padding: 20px;
    flex-direction: column;
    height: auto;
  }
`;

const FooterRow = styled.div`
  display: flex;
  height: 5vh;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #111;
  color: #ffffff;

  @media (max-width: 812px) {
    flex-direction: column;
    height: auto;
  }

  > span {
    margin-left: 10px;
  }

  a {
    color: #ffffff;
    text-decoration: underline;
    font-weight: 700;
  }
`;

const Logo = styled.div`
  font-family: "Bebas Neue", "Courier New", Courier, monospace;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 40px;

  @media (max-width: 812px) {
    font-size: 18px;
  }

  > svg {
    margin-right: 12px;
  }
`;

const IntersectLogoImg = styled.img`
  width: 120px;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 812px) {
    display: none;
  }
`;

const Tab = styled.div`
  color: ${p => (p.selected ? "#000000" : "#9D9D9D")};
  font-family: "Bebas Neue", "Courier New", Courier, monospace;
  font-size: 21px;
  margin-left: 2vw;
  margin-right: 2vw;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: 0.3s all;

  :hover {
    border-bottom-color: #000;
    color: #000;
  }
`;

const MobileTabSelector = styled(Select)`
  width: 60vw;
  margin-top: 20px;

  @media (min-width: 813px) {
    display: none;
  }
`;

const LogoCircles = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8.92857"
      cy="8.92857"
      r="8.92857"
      fill="#FF0000"
      fill-opacity="0.5"
    />
    <circle
      cx="16.0715"
      cy="16.0714"
      r="8.92857"
      fill="#FF0000"
      fill-opacity="0.5"
    />
  </svg>
);

const TAB_OPTIONS = [
  "All States",
  "Specific States",
  "Daily Change",
  "County Level",
  "States Heatmap",
  "County Heatmap"
];

const Visualizers = [
  NationwideVisualizer,
  SpecificStatesVisualizer,
  DailyChangeVisualizer,
  CountyLevelVisualizer,
  StateHeatMap,
  CountyHeatMap
];

function App() {
  const [selectedTab, setSelectedTab] = useState(0);

  const renderedTabs = TAB_OPTIONS.map((option, index) => (
    <Tab selected={selectedTab === index} onClick={() => setSelectedTab(index)}>
      {option}
    </Tab>
  ));

  const SelectedVisualizer = Visualizers[selectedTab];

  return (
    <AppContainer>
      <NavBar>
        <Logo>
          {LogoCircles}
          COVID-19 Visualizer
        </Logo>
        <Tabs>{renderedTabs}</Tabs>
        <MobileTabSelector
          defaultValue={0}
          onChange={(value) => setSelectedTab(value)}
        >
          {TAB_OPTIONS.map((tab, index) => (
            <Select.Option value={index} key={index}>{tab}</Select.Option>
          ))}
        </MobileTabSelector>
      </NavBar>
      <VisualizationWrapper>
        <SelectedVisualizer />
      </VisualizationWrapper>
      <FooterRow>
        <span>
          Created by{" "}
          <a
            href="https://www.intersectlabs.io"
            alt="Intersect Labs"
            target="_blank"
            rel="noopener noreferrer"
          >
            Intersect Labs
          </a>{" "}
          using the latest open-source Coronavirus{" "}
          <a
            href="https://github.com/nytimes/covid-19-data"
            alt="Github Covid-19 Data"
            target="_blank"
            rel="noopener noreferrer"
          >
            data
          </a>{" "}
          from The New York Times
        </span>
        <a
          href="https://www.intersectlabs.io"
          alt="Intersect Labs"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IntersectLogoImg src={IntersectLogo} />
        </a>
      </FooterRow>
    </AppContainer>
  );
}

export default App;
